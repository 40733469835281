/* Estilo general */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #181818;  /* Fondo oscuro */
  color: #ffffff;  /* Color de texto general blanco */
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Estilo de navegación (si aplica) */
nav {
  width: 100%;
  background-color: #1a1a1a;  /* Fondo ligeramente más claro para la navegación */
  padding: 15px 0;
  text-align: center;
}

nav a {
  margin: 0 15px;
  color: #333030;  
  text-decoration: none;
  transition: color 0.3s;
}

nav a:hover {
  color: #00ff00;  /* Color verde al pasar el mouse */
}

/* Estilo para el contenedor de artículos */
.article-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* espacio entre artículos */
  justify-content: center;
  padding: 20px;
  background-color: #282828;  /* Un fondo ligeramente más claro que el fondo general para contraste */
  border-radius: 8px; /* bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Sombra suave para darle profundidad */
}

/* Estilo para cada artículo dentro del contenedor */
.article-container article {
  width: 300px;  /* Puedes ajustar según tus necesidades */
  padding: 15px;
  background-color: #383838;  /* Un fondo aún más claro para cada artículo */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave para darle profundidad a cada artículo */
  transition: transform 0.3s;  /* Transición suave al pasar el mouse */
}

.article-container article:hover {
  transform: translateY(-10px);  /* Efecto de "levantamiento" al pasar el mouse */
}

/* Estilo para títulos de los artículos */
.article-container h3 {
  color: #00ff00;  /* Verde para títulos de artículos */
  text-align: center;
  margin-bottom: 10px; /* espacio debajo del título */
}

/* Estilo para títulos */
h1, h2, h3 {
  color: #00ff00;  /* Verde para títulos */
}

/* Estilo para enlaces generales */
a {
  color: #00ff00;  /* Verde para enlaces */
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #343232;
}

/* Para listas y textos destacados */
strong {
  color: #ff0000;  /* Rojo para texto destacado */
}

/* Puedes agregar o ajustar más estilos según tus componentes y necesidades */
body {
  background-image: url('./images/imagen_fondo.jpg');
  background-size: 70%;
  background-repeat: repeat;
  background-position: center;
}

