/* Estilo para el contenedor principal */
.wedogis-home {
    padding: 20px;
    background-color: #282828;  /* Fondo ligeramente más claro que el fondo general */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);  /* Sombra para añadir algo de profundidad */
    margin-top: 20px;  /* Espacio en la parte superior para separarlo del navbar u otros elementos */
    color: #ffffff;  /* Color de texto blanco */
  }
  
  /* Estilo para títulos */
  .wedogis-home h1, .wedogis-home h2 {
    color: #00ff00;  /* Verde para títulos */
    text-align: center;  /* Centrar títulos */
  }
  
  .wedogis-home h1 {
    margin-bottom: 20px;  /* Espacio adicional debajo del título principal */
  }
  
  /* Estilo para los párrafos */
  .wedogis-home p {
    margin-bottom: 16px;  /* Espacio entre párrafos */
    line-height: 1.6;  /* Espacio entre líneas para mejorar la legibilidad */
  }
  
  /* Estilo para la lista de áreas de experiencia */
  .wedogis-home ul {
    list-style-type: none;  /* Remover viñetas */
    padding-left: 0;  /* Remover padding a la izquierda */
  }
  
  .wedogis-home ul p {
    margin-left: 15px;  /* Indentación para cada elemento */
    font-weight: bold;  /* Texto en negrita para cada área de experiencia */
  }
  
  /* Texto destacado */
  .wedogis-home strong {
    color: #ff0000;  /* Rojo para texto destacado */
  }
  