/* Estilos para la barra de navegación oscura */
.navbar.bg-light {
    background-color: #333 !important; /* Sobreescribe el fondo claro por defecto con un color oscuro */
}

/* Título de la barra de navegación */
.navbar h1 {
    color: #fff !important; /* Asegura que el color del título sea blanco */
}

/* Estilo para el link activo */
.nav-link-active {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  transition: box-shadow 0.3s ease;
  color: #ffdd57 !important;
}

/* Estilo para los links en una barra oscura */
.nav-link {
    color: #b0b0b0 !important;
}

.nav-link:hover {
    color: #ffffff !important;
}
.nav-link {
    color: white;
    transition: 0.3s;
  }
  
  .nav-link:hover {
    color: #02a3fe;
  }
  
  .nav-link.active {
    color: #02a3fe;
    font-weight: bold;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
  }
  .nav-link {
    color: white;
    transition: 0.3s;
    padding: 5px 15px; /* Añade un padding para dar espacio al recuadro */
    border-radius: 5px; /* Añade bordes redondeados al recuadro */
  }
  
  .nav-link:hover {
    color: #02a3fe;
    background-color: rgba(255, 255, 255, 0.1); /* Añade un fondo al pasar el mouse */
  }
  
  .nav-link.active {
    color: #02a3fe;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.2); /* Añade un fondo más opaco para el enlace activo */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Aumenta la sombra para más énfasis */
  }
  
  