.registration-form-container {
  width: 100%;
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: black; 
}

.registration-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: black; 
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: black; 
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea { 
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s;
  color: black; 
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group textarea:focus { 
  border-color: #007BFF;
  outline: none;
}

.form-group button {
  width: 100%;
  padding: 10px 15px;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  color: #fff; 
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.form-group button:hover {
  background-color: #0056b3;
}
