.portfolio-container {
  max-width: 100%;
  margin: 0 auto;
  background-color: transparent;
  overflow: auto;
  padding: 50px; /* Modifica el padding para separar la presentación y los videos */
}

/* Estilos para la presentación y el enlace */
.portfolio-introduction {
  text-align: right;
  margin-bottom: 20px;
  color: white; /* Cambia el color del texto a blanco o el color que prefieras */
  font-weight: bold; /* Hace que el texto esté en negrita */
  font-size: 1.1em; /* Aumenta el tamaño de la fuente al 110% del tamaño original */
  border: 2px solid transparent; /* Agrega un borde transparente alrededor de la sección */
  border-radius: 8px; /* Agrega bordes redondeados */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Agrega una transición similar a la de los videos */
  padding: 20px; /* Aumenta el espacio interior para que coincida con los videos */
  background-color: rgba(0, 0, 0, 0.1); /* Cambia el color de fondo a uno ligeramente transparente */
}

.portfolio-introduction:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transform: scale(1.03); /* Aplica un efecto de escala al pasar el mouse */
}
.portfolio-introduction img {
  max-width: 7%;
  height: auto;
}

.portfolio-introduction a {
  display: block;
  margin-top: 10px;
  color: #0073e6; /* Cambia el color del enlace a uno que prefieras */
}

/* Estilos para los videos */
.video-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  border-radius: 8px;
  background-color: transparent;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
}

.video-section:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.video-container, .video-info {
  flex: 1;
  padding: 10px;
}

.youtube-video-container iframe {
  width: 100%;
  height: auto;
}

.portfolio-container h3, .portfolio-container p {
  margin: 0;
  padding: 0;
  color: white; /* Cambia el color del texto de los videos a blanco o el color que prefieras */
}

@media (max-width: 768px) {
  .video-section {
    flex-direction: column;
  }

  .video-section:hover {
    transform: none;
  }
}
